<template>
    <div class="root">

        <el-card class="box-card status-card" style="background-color: #7db362">
            <el-row type="flex" align="middle">
                <el-col :span="6">
                    <i class="icon el-icon-files"></i>
                </el-col>
                <el-col :span="18">
                    <p>总爬取：<strong>{{datas.hot_total}}</strong></p>
                    <p>今日爬取：<strong>{{datas.hot_today}}</strong></p>
                </el-col>
            </el-row>
        </el-card>

        <el-card class="box-card status-card" style="background-color: #d6a459">
            <el-row type="flex" align="middle">
                <el-col :span="6">
                    <i class="icon el-icon-view"></i>
                </el-col>
                <el-col :span="18">
                    <p>浏览量：<strong>{{datas.view_total}}</strong></p>
                    <p>今日浏览量：<strong>{{datas.view_today}}</strong></p>
                </el-col>
            </el-row>
        </el-card>

        <el-card class="box-card status-card" style="background-color: #77a0ca">
            <el-row type="flex" align="middle">
                <el-col :span="6">
                    <i class="icon el-icon-user"></i>
                </el-col>
                <el-col :span="18">
                    <p>总用户量：<strong>{{datas.user_total}}</strong></p>
                    <p>今日注册：<strong>{{datas.user_today}}</strong></p>
                </el-col>
            </el-row>
        </el-card>

        <p class="btn-box">
            <el-popconfirm title="消耗资源极大，确定执行一次全平台爬取吗？" @onConfirm="crawAll">
                <el-button size="small" slot="reference" :loading="tableloading">全平台爬取</el-button>
            </el-popconfirm>
            <el-button v-if="datas.hot_total === '-'" size="small" type="primary" :loading="tableloading" @click="loadData">获取数据</el-button>
        </p>

        <img class="bg-img" src="../assets/lang.png" alt="">

        <el-card class="box-card" style="margin-top: 20px">
            <div slot="header" class="clearfix">
                <span>最新爬取错误
                    <el-tooltip class="item" effect="dark" :content="'更新于 ' + errorUpdate" placement="bottom-start">
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </span>
                <el-button style="float: right; padding: 4px 0" type="text" @click="$router.push('/craw-error')">查看所有...</el-button>
            </div>

            <el-table :data="tableData" stripe style="width: 100%" v-loading="tableloading">
                <el-table-column prop="id" label="ID" width="80"></el-table-column>
                <el-table-column prop="source_name" label="平台名"></el-table-column>
                <el-table-column prop="content" label="错误信息" min-width="350"></el-table-column>
                <el-table-column label="创建日期">
                    <template slot-scope="item">
                        {{base.formatTime(new Date(item.row.create_time))}}
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

    </div>
</template>

<script>
    // let timer = null;
    export default {
        data() {
            return {
                datas: {
                    hot_total: '-',
                    hot_today: '-',
                    view_total: '-',
                    view_today: '-',
                    user_total: '-',
                    user_today: '-',
                },
                errorUpdate: '-',
                tableloading: false,
                tableData: []
            }
        },
        created() {
            let that = this
            that.loadTable()
            // that.loadData()
            // 定时一分钟刷新一次
            // timer = setInterval(() => {
            //     that.loadTable()
            // }, 60000)
        },
        beforeDestroy() {
            // clearInterval(timer)
        },
        methods: {
            loadTable() {
                let that = this
                this.ajax({
                    url: '/hot/craw_error?size=10',
                    beforeSend() {
                        that.tableloading = true
                    },
                    complete() {
                        that.tableloading = false
                    },
                    success(res) {
                        that.tableData = res.data.list
                        that.errorUpdate = that.base.formatTime(new Date())
                    }
                })
            },
            loadData() {
                let that = this
                this.ajax({
                    url: '/root/data',
                    beforeSend() {
                        that.tableloading = true
                    },
                    complete() {
                        that.tableloading = false
                    },
                    success(res) {
                        that.datas = res.data
                    }
                })
            },
            crawAll() {
                let that = this
                this.ajax({
                    url: '/root/crawAll',
                    type: 'post',
                    success() {
                        that.$message({
                            message: '启动爬取成功',
                            type: 'success'
                        });
                    },
                    error() {
                        that.$message({
                            message: '启动爬取失败',
                            type: 'error'
                        });
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .root {
        position: relative;
        height: 100%;
        overflow: hidden;

        .status-card {
            color: #fff;
        }
    }

    .icon {
        font-size: 35px;
    }

    .status-card {
        display: inline-block;
        width: 300px;
        margin-right: 20px;
    }

    .bg-img {
        position: absolute;
        width: 850px;
        bottom: 0;
        left: 0;
        z-index: 1;
        opacity: 0.2;
        transform: translate(-160px, 273px);
    }

    .btn-box {
        padding-top: 20px;

        >span {
            margin-right: 20px;
        }
    }
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import ajax from './assets/ajax'
import base from './assets/base'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueRouter)

Vue.prototype.ajax = ajax
Vue.prototype.base = base

// 配置页面路由
import Home from './page/home'
const router = new VueRouter({
  routes: [
    { path: '/', name: '首页', component: Home },
    { path: '/login', name: '登录', component: () => import('./page/login') },
    { path: '/user', name: '用户列表', component: () => import('./page/user') },
    { path: '/source', name: '平台设置', component: () => import('./page/source') },
    { path: '/hot-list', name: '今日爬取', component: () => import('./page/hot-list') },
    { path: '/craw-error', name: '爬取错误', component: () => import('./page/craw-error') },
    { path: '/icon-file', name: '图标配置', component: () => import('./page/icon-file') },
  ]
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

<template>
    <div id="app">

        <el-container style="height: 100%;">

            <el-aside style="width: 220px">
                <img class="logo" src="./assets/logo-pure.png" alt="">
                <el-menu :default-active="$route.path" background-color="#313F58" text-color="#fff" active-text-color="#fff" :router="true">

                    <el-menu-item index="/">
                        <i class="el-icon-s-home"></i>
                        <span>首页</span>
                    </el-menu-item>

                    <el-menu-item index="/source">
                        <i class="el-icon-s-platform"></i>
                        <span>平台配置</span>
                    </el-menu-item>

                    <el-menu-item index="/user">
                        <i class="el-icon-user"></i>
                        <span>用户列表</span>
                    </el-menu-item>

                    <el-menu-item index="/hot-list">
                        <i class="el-icon-time"></i>
                        <span>今日爬取</span>
                    </el-menu-item>

                    <el-menu-item index="/craw-error">
                        <i class="el-icon-error"></i>
                        <span>爬取错误</span>
                    </el-menu-item>

                    <el-menu-item index="/icon-file">
                        <i class="el-icon-setting"></i>
                        <span>图标配置</span>
                    </el-menu-item>

                </el-menu>
            </el-aside>

            <el-container>

                <el-header>
                    <el-button type="primary" plain size="mini" @click="logout" :loading="logoutLoading">退出登录</el-button>
                </el-header>

                <el-main>
                    <transition name="fade">
                        <router-view></router-view>
                    </transition>
                </el-main>

            </el-container>
        </el-container>

    </div>
</template>

<script>
    export default {
        name: 'App',
        data() {
            return {
                logoutLoading: false
            }
        },
        components: {},
        methods: {
            logout() {
                let that = this
                that.$confirm('确定退出登陆吗？')
                    .then(() => {
                        that.ajax({
                            url: '/root/logout',
                            type: 'GET',
                            beforeSend() {
                                that.logoutLoading = true
                            },
                            complete() {
                                that.logoutLoading = false
                            },
                            success() {
                                that.$message({
                                    message: '退出登录成功',
                                    type: 'success'
                                });
                                that.$router.push('/login')
                            },
                            error(res) {
                                that.$message.error(res.message);
                            },
                        })
                    })
                    .catch(() => {})
            }
        }
    }
</script>

<style lang="less">
    html,
    body {
        height: 100%;
        box-sizing: border-box;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
    }

    #app {
        font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
            "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #000c45;
        height: 100%;
    }

    .el-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 1;
        border-bottom: 1px solid #ebeef5;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

    .el-aside {
        text-align: center;
        background-color: #313f58;

        .logo {
            width: 70px;
            padding: 40px 0;
        }

        .el-menu {
            border-right: none;
        }

        .el-menu-item,
        .el-submenu__title {
            border-left: 5px solid transparent;
            padding-left: 0 !important;
        }

        .el-submenu__title i {
            color: #dedede;
        }

        .el-menu-item.is-active {
            background-color: #3f5274 !important;
            border-left-color: #2d7da5;
        }

        .el-submenu .el-menu-item {
            padding-left: 80px !important;
        }

        .el-submenu .el-menu-item {
            min-width: unset;
        }
    }

    .el-main {
        background-color: #fbf9fa;
    }
</style>